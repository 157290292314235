import { WHITEBOARD_UI_OPTIONS } from "../utils/constants";
import { useCallback, useRef } from "react";
import { ExcalidrawApp } from "@jitsi/excalidraw";
import { useLocation } from "react-router-dom";
import { getCollabServerUrl } from "../utils/functions";

export default function WhiteBoard() {
  let location = useLocation();

  const collabAPIRef = useRef<any>(null);
  const collabDetails = useRef<any>(null);
  const collabServerUrl = useRef<any>(null);

  const queryParams = new URLSearchParams(location.search);
  const gridStyle = queryParams.get("grid");
  const roomId = queryParams.get("room");
  const key = queryParams.get("key");
  const name = queryParams.get("name") || "Anonymous";
  const edit = queryParams.get("edit");

  const getCollabAPI = useCallback(
    (collabAPI: any) => {
      if (collabAPIRef.current) {
        return;
      }
      collabAPIRef.current = collabAPI;
      collabAPIRef.current.setUsername(name);
    },
    [name]
  );

  if (!roomId || !key) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>NIT</h1>
        <h2>Whiteboard ERROR</h2>
        <p>Collaborative whiteboard for NIT</p>

        <p>Room and key must be provided.</p>
      </div>
    );
  }

  collabDetails.current = {
    roomId: roomId as string,
    roomKey: key as string,
  };
  collabServerUrl.current = getCollabServerUrl(roomId as string);

  return (
    <>
      <div style={{ height: "100vh" }}>
        {collabDetails.current && (
          <ExcalidrawApp
            collabServerUrl={collabServerUrl.current}
            collabDetails={collabDetails.current}
            excalidraw={{
              isCollaborating: true,
              gridModeEnabled: gridStyle === "true" ? true : false,
              viewModeEnabled: edit === "true" ? false : true,
              zenModeEnabled: edit === "true" ? false : true,
              theme: "light",
              UIOptions: WHITEBOARD_UI_OPTIONS,
            }}
            getCollabAPI={getCollabAPI}
          />
        )}
      </div>
    </>
  );
}
