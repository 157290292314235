import md5 from "js-md5";

/**
 * Adds new param to a url string. Checks whether to use '?' or '&' as a separator (checks for already existing params).
 *
 * @param {string} url - The url to modify.
 * @param {string} name - The param name to add.
 * @param {string} value - The value for the param.
 *
 * @returns {string} - The modified url.
 */
function appendURLParam(url: string, name: string, value: string) {
  const newUrl = new URL(url);

  newUrl.searchParams.append(name, value);

  return newUrl.toString();
}

export const getCollabServerUrl = (room: string) => {
  const collabServerBaseUrl = "https://whiteboard-api.nit.lionwood.software";

  if (!collabServerBaseUrl) {
    return;
  }

  const hashRoom = md5.hex(room);

  return appendURLParam(collabServerBaseUrl, "room", hashRoom);
};
