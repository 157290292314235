/**
 * Whiteboard UI Options.
 */
export const WHITEBOARD_UI_OPTIONS = {
  canvasActions: {
    allowedShapes: [
      'arrow',
      'diamond',
      'ellipse',
      'freedraw',
      'line',
      'rectangle',
      'selection',
      'text',
    ],
    allowedShortcuts: ['cut', 'deleteSelectedElements', 'redo', 'selectAll', 'undo'],
    disableAlignItems: true,
    disableFileDrop: true,
    disableGrouping: true,
    disableHints: true,
    disableLink: true,
    disableShortcuts: true,
    disableVerticalAlignOptions: true,
    fontSizeOptions: ['s', 'm', 'l'],
    hideArrowHeadsOptions: true,
    hideColorInput: true,
    hideClearCanvas: true,
    hideFontFamily: true,
    hideHelpDialog: true,
    hideIOActions: true,
    hideLayers: true,
    hideLibraries: true,
    hideLockButton: true,
    hideOpacityInput: true,
    hideSharpness: false,
    hideStrokeStyle: false,
    hideTextAlign: false,
    hideThemeControls: true,
    hideUserList: false, // hide user list in the canvas
    saveAsImageOptions: {
      defaultBackgroundValue: true,
      disableScale: true,
      disableSelection: true,
      disableClipboard: true,
      disableSceneEmbed: true,
      hideTheme: true,
    },
  },
}
